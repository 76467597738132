/******************************************************************
Site Name:
Author:

Stylesheet: Base Mobile Stylesheet

Be light and don't over style since everything here will be
loaded by mobile devices. You want to keep it as minimal as
possible. This is called at the top of the main stylsheet
and will be used across all viewports.

******************************************************************/

/*********************
GENERAL STYLES
*********************/
body {
	font-family: $sans-serif;
	font-size: 98%;
	line-height: 1.6;
	color: $black;
    font-weight:400;
} 


.mobileOnly {display:inline-block!important;}
.desktopOnly {display:none!important;}

/********************
BODY CLASSES
style a page via class
********************/

.home {}    /* home page */


/*********************
Z_INDEX
*********************/
.cd-top { z-index: 9999;}
.header { z-index: 901;}
#bookForm { z-index: 900;}

ul.children {z-index: 800;}

.caption {z-index: 9;}
/*********************
LAYOUT & GRID STYLES
*********************/

.row, body.Home #content #leftcol > div > div, body.home #content #leftcol > div > div, .row-small, body.fullWidth #leftcol > div > div {
    margin: 0 auto;
    width: 95%;
    padding: 1em 0;
}

/* Turn off padding on some rows*/
.header .row { padding: 0 0;}



/*********************
LINK STYLES
*********************/

a, a:visited {
	color: $link-color;

	/* removing text decoration from all headline links */
		text-decoration: none;

	/* on hover */
	&:hover, &:focus {
		color: $link-hover;
	}

	/* on click */
	&:active {

	}

	/* mobile tap color */
	&:link {
		/*
		this highlights links on iPhones/iPads.
		so it basically works like the :hover selector
		for mobile devices.
		*/
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}

.link {
    &:after {
        content: "\f061";
        font-family: "Font Awesome 6 Pro";
        font-weight: 300;
        display: inline-block;
        padding-left: 0.5em;
        line-height: 1;
    }
}

/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/

h1, .h1, h2, .h2, h3, .h3, .siteName {
    color: $black;
	a {
		text-decoration: none;
	}
}
/*weights*/

h3, .h3, h4, .h4 {font-weight:500;}
h1, .h1, h2, .h2, label, .button, .topMenu .nav > li, .link-2, #accountMenu > a:first-of-type {font-weight:600;}

/*margins*/
h1, .h1, h2, .h2, h3, .h3, .gmb-review p { margin: 0.25em 0;}
h3, .h3, h4, .h4, .gmb-review p { margin: 0.75em 0;}

p + h3, p + .h3 {margin: 1em 0 0.5em 0;}

/*line-height*/
h1, .h1  { line-height: 1.3;}
h2, .h2,h3, .h3, h4, .h4, label, [data-control]  { line-height: 1.4;}

/*letter spacing*/
.xsmtext, .menu-btn span,  .button { letter-spacing:1px; }

/*sizing*/
.grid-3 .card-image i { font-size: 3.5em; }
h1, .h1 { font-size: 2em; }
h2, .h2 { font-size: 1.6em; }
.bigger, h3, .h3, [data-control] {font-size:1.2em;}
.smtext, #breadcrumbs, thead, .respond-form label, .holdForm ul, #pushMenu .nav-small, .news-item-label, #message { font-size: 0.85em; }
.xsmtext, .menu-btn span { font-size: 0.65em; }

.responsive .h3:after {display:none; margin-bottom:0; }


/*styling*/ 
h4, .h4, .step, #pushMenu ul > li > a { text-transform: uppercase;}
h1, .h1, h2, .h2{ letter-spacing:-1px;}
h4, .h4, .step, #pushMenu ul > li > a{ letter-spacing:1px;}

#rightcol h4, #rightcol .h4 {
    position: relative;
    text-align: center;
    color: black;
    margin-bottom: 1.5em;
    
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: 200%;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      background-color: $color-secondary;
    }  
  
    &:after {
      content: '';
      position: absolute;
      width:20%;
      height: 3px;
      top:200%;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: $color-secondary;
      @include transition(all 0.2s ease-in-out);
    }
  
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }







/*************************
Colours
*************************/	


.blue, .darkHeader #accountMenu, #accountMenu .h2, .darkHeader .phone, body.home #content ul li::before, blockquote:before, body.Home .columnlayout h2 a:hover, h3, .h3,  
.respond-form h2, .fa-li, .slideshow_caption a.button, .button.secondary, a.button.secondary,  a.button.secondary:hover, .h1, h1, .h2, h2
{color: $color-primary;}


.black, .topMenu .nav > li > a,
a.button, button, #bottomBlocks, #bottomBlocks a, #bottomBlocks .h2 {
    color: $black;
}

.grey, 
{color: lighten($black, 40%); }

.white, #pushMenu .nav li a, .pushy a,
.topMenu .nav li ul li a,
ul.responsive3 .slideshow_caption a,
.slick-slider button:before,
.responsive .h3,
.holdForm, .holdForm h2, .holdForm a.button,
#footer #facebook a, #copyright, #footer, #footer a, .cd-top i, #cta a.button, .button.primary, a.button.primary, a.button:hover, .button.tertiary,
#hero .slick-slider button:before, #hero .heading h1, #hero .heading .h1,
#homeMission,
 #breadcrumbs, #breadcrumbs a, {
    color: $white;
}

.orange {color: $alert-yellow;}





/*************************
Turn off printable items
*************************/	
	.printable {display: none;}
	

/*********************
SPRITE
*********************/
/*use modernizer script stop detect browser fallback for next gen images*/
/*.no-webp .box { color: red; }
.webp .box { color: green; }*/	
/*.no-js #logo, .no-webp #logo { background-image: url('../images/logo.png'); }
.webp #logo { background-image: url('../images/logo.webp'); }*/

    #logo {
		display: block;
		margin: 0 auto;
        background: transparent url('../images/logo.svg') no-repeat center top;
        background-position: center top;
        width: 147px; height: 65px;
        background-size: 147px 65px;
	}

/*.clearHeader {
    #logo {
        background: transparent url('../images/logowhite.svg') no-repeat center top;
        @include transition(all 0.3s ease-in-out);
    }
}*/



/*********************
HEADER STYLES
*********************/

/* STICKY NAV - This is where the header is set to be sticky. 
We can also change colour and size here - effects all take place onScroll (waypoints triggers this) */


.header {

    .clearHeader {
        position: fixed;
        top: 0;
        background-color: rgba(255,255, 255, 0.90);
        border-bottom: 1px solid rgba(38,61, 144, 0.75);
        @include transition(all 0.4s ease-in-out);
    }

    .darkHeader {
        position: fixed;
        top: 0;
        background-color: rgba(255,255, 255, 0.90);
        border-bottom: 1px solid rgba(38,61, 144, 0.75);
        @include transition(all 0.4s ease-in-out);
    }
}

/* position header elements as needed */

.header {
    position: relative;
    z-index: map-get($zindex, header);

    .row {
        min-height: 80px;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    .menu {
        width: 100%;
    }

    #mainMenu, #accountMenu {
        align-items: center;
        flex-basis: auto;
        flex-grow: 1;
        position: absolute;
    }

    #logo {
        display: inline-block;
    }

    #accountMenu {
        right: 2%;
        @include center(false, true);
        text-align: right;
        margin-right: 0.5em;

        .mobileOnly {
            margin-left: 0.5em
        }

        a:not(.button) {
            padding: 0.5em 0em;
            display: inline-block;
            margin: 0em 0.35em;
        }
    }


    .menu-btn {
        left: 2%;
        @include center(false, true);
        text-align: center;
        margin: 0 0.25em;
        display: block;
        text-align: right;

        span {
            display: block;
            line-height: 1.5;
        }
    }


    #logo {
        @include center(true, false);
        top: 0.5em;
    }

    #mainMenu {
        display: none;
    }
}







/***********************
HERO - HERO
************************/



#hero {
    position: relative;
    overflow: hidden;
    height: 95vh;
    z-index: map-get($zindex, sendBackwards);

    &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 0;
    }

    .row {
            @include center(true, true);
            width: 85%;

    }
}

body.Template-2 #hero .caption { @include center(false, true); width: 85%; left: 10%; z-index: 9;}

body:not(.Home):not(.home) #hero {
    position: relative;
    height:250px;
    height:55vh;

    .heading{ @include center(true, false); top:45%;}
    p img {min-height: 600px; max-width: none;z-index: map-get($zindex, sendToBack);@include center(true, true);}
    .heading { padding:1em; width:90%; z-index:2; text-align:center; }

    &:before {content:""; position:absolute; display: block; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.35); z-index: map-get($zindex, sendBackwards);}


}




.custom-shape-divider-bottom-1707967154 {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1707967154 svg {
    position: relative;
    display: block;
    width: calc(123% + 1.3px);
    height: 212px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1707967154 .shape-fill {
    fill: #eff9fd;
}
/*********************
NAVIGATION STYLES
*********************/
#touchMenu,
.menu-btn {display:block;}

#mainMenu {
    display:none;
}

#pushMenu {
    ul:nth-of-type(2){
        > li:first-of-type{display:none;} /*hide home link in 2nd nav*/
    }
}

.topMenu, #pushMenu {
	/*list-style:none;
	float: right;
	clear: right;*/
		
		
		a.toggleMenu {
			width: 100%; 
			padding: 2% 4%;
			text-transform: uppercase;
			margin-bottom: 0;
				span {
					display: block; 
					width:auto; 
					float:right;
					padding-left: 0.5em;
				}
					
		}
		
		.nav {
			/*margin-top:0;
			position: absolute;
			width: 96%;
			z-index: 99999;*/
		
				li {
					position: relative;
					text-align: left;
					a {
						display: block;
						text-decoration: none;
						padding: 0.5em 0.5em;
						background: darken($black, 10%);
						border-bottom: 1px solid rgba(130, 154, 193, 0.21);
				
						&:hover, &:focus {
							background: darken($black, 5%);
						}
						&.parent:before {
							content: "\f107";
							font-family: "Font Awesome 6 Pro";
                            font-weight: 300;
							display: block;
							float: right;
							font-size: large;
							padding-right: 0.5em;
							line-height: 1.2;
						}
					}
					&.hover {
						> a.parent:before {
							content: "\f106";									
						}
					}
			
					&:first-child {}
			
					&:last-child {}
			
					/* Sub-menu level 2 */
					ul,
					ul.sub-menu,
					ul.children {
						margin-top: 0;
						border-top: 0;
						display: none;
			
						li {
			
							a {
								padding-left: 1em;
								background: darken($black, 5%);
								border-bottom: 1px solid rgba(130, 154, 193, 0.21);
											
								&:hover,
								&:focus {
									background: darken($black, 0%);
								}			
								&:link {}
								
								
							}
			
							&:first-child {}
			
							&:last-child {}
							
							/* Sub-sub-menu level 3	*/
							ul {
													
								li {
					
									a {
										padding-left: 1.5em;												
										background: $black;
										&:hover,
										&:focus {
											background: lighten($black, 5%);
										}
									}
									
									/* Sub-sub-menu level 4	*/
									ul {
															
										li {
							
											a {
												padding-left: 2em;
												background: lighten($black, 30%);
												
											}
							
										}
							
									}/* end level 4	*/
					
								}
					
							}/* end level 3	*/
			
						}
			
					}/* end level 2	*/
					
					/* Hover effects */
					&:hover ul {
						
					}
					
			
				} /* end .menu li */
			
			
		
		} /* end .nav */

        .nav > li.hover > ul { left: 0; display: block;}
        .nav li li.hover > ul { left: 100%; top: 0; display: block; }

}/* end .topMenu */



/*********************
CONTENT STYLES
*********************/
/* content area all inner pagesset different or alternate row colours here*/
body.fullWidth #content #leftcol > div:not(#div437):not(#div423):nth-of-type(odd),
body.Home #content #leftcol > div:not(#div406):nth-of-type(odd),
#intro {
    background: lighten($color-secondary, 45%);
}

#div406, #div437, #div423, #div445 {
    background: $color-primary;

    p, h2, h4 {
        color: $white;
    }
}
/* content area all inner pages */
#content, #homeBlocks, #bottomBlocks {
    position: relative;
    background: $white;
}





.fa-ul { padding-left: 60px; }
	
			p {}


			ul, ol, table, dl {}

#content ul {
    list-style: none;
    padding: 0 0 0 0;
    margin: 1.5em 0em;

    li:not(.slick-slide):not(ul.rslides li):not(ul.gallerydetails li) {
        position: relative;
        padding-left: 2.5em;
        margin-bottom: 1em;

        &:before {
            content: "\f058";
            font-family: "Font Awesome 6 Pro";
            color: $color-primary;
            font-weight: 300;
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            font-size: 1.5em;
            line-height: 1.1;
        }
    }
}

            .col-2 {
                display: grid;
                grid-gap: 0.75em;
                grid-template-columns: repeat(2, 1fr);
                margin-bottom: 1em;

                > div, > li {
                    grid-column: span 2;
                    /*border-radius: 0.5rem;
                    padding: 0.75em 1em 0.75em 1em;
                    border: 1px solid #0D0D0D;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;*/
                }
            }

			ol {

				li {}

			}

			blockquote {
				margin: 0 0 0 0;
				text-align: left;
								
			}
			
			blockquote {
				padding: 1em;
				p {
					padding-left: 2em;
					margin-top: 0;
				}
				h5 {
					margin: 0.4em 0;
				}
								
				&:before {
					content: "\f10d";
					font-family: "Font Awesome 6 Pro";
                    font-weight: 300;
					display: block;
					float:left;
					padding-right: 0.5em;
					line-height: 1.2;
									
				}
				
				a {
					text-decoration: none;
				}
				
				em{
					text-align: right;
					}

			}

			dl {}

			dt {}

			dd {
				margin-left: 0;
				font-size: 0.9em;
				color: #787878;
				margin-bottom: 1.5em;
			}

			img {
				/*margin: 0 0 1.5em 0;*/
				max-width: 100%;
				height: auto;
			}


	#breadcrumbs { 
		padding: 1em;
				
		> div {
            text-align: center;
             div {
			    display: inline;
            }
		}
	}



/*********************************
 BLOCKS
*********************************/

img {@include border-radius($small-border-radius);}
 
.block {}

.columnlayout {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    padding-left: 0;
    justify-content: center;
    gap: 1.5em;
    /*turn off basic li styling*/
    padding: 0 0 0em 0;

    li:before {
        display: none;
    }

    li {
        margin-bottom: 0em;
        padding-left: 0em;
    }

    > div, li, article {
        display: inline-block;
        vertical-align: top;
        flex: 1 1 auto;
        flex-grow: 0;
        width: 100%;

        > p:first-of-type {
            margin: 0 auto;
        }

        > div {
            width: auto;
        }
    }

    article.newsBlock {
        width: 100%;
    }
    //confidence trigger icons
    &.grid-3 {
        > div .card-image, > div .card-item {
            width: auto;
        }

        > div .card-image {
            i {
                line-height: 1.6;
            }
        }

        > div .card-item {
            flex-grow: 1;

        }

        > div {
            display: -webkit-flex;
            display: flex;
            flex-wrap: nowrap;
            gap: 1.5em;
        }
    }

    &.news {
        /*hide admin code from front-end*/
        > div[align="center"], > a {
            display: none;
        }
    }
}

   /* #gallery */
   #gallery li {padding:0 0.25em;}

   // HOME TEMPLATE 2
body.Template-2 {
    /*Services list*/
    .columnlayout.services {	

	    margin-bottom: 2em;
        > div, > article {
            padding: 0 0em;
            margin-bottom:1em;
            background: $white;
            @include border-radius($small-border-radius);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                cursor:pointer;
            }

            .wrap-txt {
                padding: 1em;
            }
            .wrap-img{   
                
                display:block;
                p, picture, img {display:block; margin: 0 0; min-width:100%; border-radius: 2em 3.5em 0 0}
                picture {
                    background:$color-secondary;
                    img {
                        opacity:1;
                    }
                    &:hover img {
	                    opacity: .5;
                    }
                }
            }

            > article {
                padding: 0 0em;
            }
        }
        /*hide admin code from front-end*/
        > div[align="center"], > a {display:none;}
    }
}

.columnlayout.grid-2, .columnlayout.grid-3 {
    justify-content: space-between;

    > div {
        display: flex;
        padding: 0 0.5em;
        margin-bottom: 1em;
        padding-top: 1em;
        flex-direction: column;

        .card-image, .card-item {
            text-align: center;
        }

        .card-image {
            width: 100%;

            p {
                margin: 0 0;
            }
        }

        .card-item {
            width: 100%;

            h4 {
                margin: 0 0;
            }

            h4 + p {
                margin-top: 0;
            }
        }
    }
}

    /* RIGHT BLOCKS */
    #rightcol {
        .block {
            margin-bottom:1em;
            .respond-form, #newsletter {
                form {
                    width: 100%;
                }
            }


            ol, ul {
                padding: 0em 0em 1em 0em;

                li {
                    list-style: none;
                    line-height: 1.6;
                    padding-left: 1.5em;
                    margin-bottom:0.25em;
                    border-bottom: 1px dotted lighten($black, 30%);

                    a {
                        display: block;
                        text-decoration: none;
                        padding: 0.5em 0 0.5em 0;
                    }
                    a:hover, a.activesibling { }
                }

                li  {
                    &:before {
                        content: "\f105";
                        font-family: "Font Awesome 6 Pro";
                        font-weight: 300;
                        display: block;
                        float: left;
                        font-size: large;
                        padding-right: 0.5em;
                        line-height: 2.2;
                    }
                }
            }
        }
        /* end .block */
    }
    /* end #rightcol */


    /*TOP BLOCKS INNER PAGES*/
  
    body:not(.Home) #topblocks {

        background-color: darken($white, 10%);

        div.block { 
            max-height: 350px;
		    position: relative;
            overflow:hidden;
            margin-bottom:0;

            > p {margin:0 0;}

            img {
              /*max-height: 500px; 
              height: 80vh;
              max-width: none;*/
              width: auto;
              height: auto;
            }
		
		    .caption {
			    @include center();
                /*background-color:rgba(255, 255, 255, 0.6);*/
			    width: 95%;
			    text-align: center;
                padding: 1em;
		    }
        }

    }
   



	
/******************************************************************
FORM STYLES
******************************************************************/
    /* show/hid fields on gift voucher form*/
    .hide {
        
    }


    .respond-form {
        margin: 0em auto;
        padding: 0%;
        min-width: 190px;
        background-color: transparent;
    }

.respond-form, #address {
    /* not nested as not all forms contain a table */
    table {
        width: 100%
    }

    form {
        margin: 0 0;

        li {
            list-style-type: none;
            clear: both;
            margin-bottom: 0.7335em;

            label,
            small {
                display: none;
            }
        }

        fieldset {
            border: 0;
            margin: 0 0;
            padding: 0 0 0 0;
        }

        input[type=text],
        input[type=email],
        input[type=date],
        input[type=url],
        input[type=tel],
        textarea,
        select {
            padding: 1em;
            background-color: transparent;
            margin: 0 0 1em 0;
            display: table-cell;
            width: 100%;
            max-width: 100%;
            border: none;
            border-bottom: 1px solid $white;
            color: $white;


            &:focus {
                /*background-color: #fff;*/
            }
            /* form validation - comment out as editor doesn't support some of the html5 validation techniques */
            /* &:invalid {
				outline: none;
				border-color: #fbc2c4;
				background-color: #f6e7eb;
				-webkit-box-shadow: none;
				   -moz-box-shadow: none;
				    -ms-box-shadow: none;
						box-shadow: none;
			}*/
        }

        input[type=checkbox] {
            margin-right: 5px;
        }

        input[name="180_LeaseEnquiry"] {
            margin-bottom: 1em;
        }


        .required {
            background-position: right top;
            background-repeat: no-repeat;
        }

        textarea {
            height: 80px;
        }

        img {
            float: left;

            &.fullwidth {
                max-width: 100%;
            }
        }
    }
}

    #address form {
        margin: 0 auto;
        input[type=email] {width:auto;padding: 0.5em;}
        input[type="submit"] {min-height: 36px;}
    }

#bottomBlocks .respond-form {
    input[type=text],
    input[type=email],
    input[type=date],
    input[type=url],
    input[type=tel],
    textarea,
    select {
        border-bottom: 1px solid $black;
        color: $black;
    }

    ::placeholder {
        color: $black;
        opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder { /* Edge 12 -18 */
        color: $black;
    }
}
/*********************
LEFT COLUMN
*********************/
    #leftcol {
        padding-bottom: 0em;

        #div307 {
            div {
                text-align: center;
            }
        }
    }


    

/*********************
FOOTER STYLES
*********************/
#bottomBlocks, #cta {
    background: linear-gradient(90deg, $color-tertiary 0%, $color-secondary 95%);
}

    #bottomBlocks {
        position:relative; 
        .bigger {font-size: 1.6em;}
        h4 {font-weight: 600;}
    }

    body.Template-2 {
        #bottomBlocks {
            .respond-form {padding-top:1em;}
        }
    }

#footer {
    background: darken($color-primary, 20%);

    > div {
        a.socials {
            display: inline-block;
            padding: 0.25em;

            i {
                padding-right: 0.25em;
            }
        }

        nav {
            
                text-align: right;
            
        }
    }
}

    /* ================================================ */
    /* SLIDESHOWS */
    /* ================================================ */
    .rslides {
        position: relative;
        list-style: none;
        overflow: hidden;
        width: 100%;
        padding: 0;
        margin: 0;

        li {
            position: absolute;
            display: none;
            width: 100%;
            left: 0;
            top: 0;
            /*padding: 2%;*/
            background: transparent;
        }

        img {
            display: block;
            height: auto;
            float: left;
            width: 100%;
            border: 0;
        }
    }

    .rslides_nav {
        display: none;
        visibility: hidden;
    }

    .slides1_nav {
        display: none;
        visibility: hidden;
    }

#leftcol li.slick-slide:before,
#leftcol ul.responsive .slick-dots li:before  {display:none;}

/* ================================================ */
/* SLIDESHOWS */
/* ================================================ */
/*.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-perspective: 1000px;
}*/
/* SLIDER */
    .slick-slider {
        padding: 0;
        list-style: none;

        .slick-list {
            margin: 0 auto;

            .slick-track {

                .slick-slide {
                    position: relative;
                	padding:0;

                    .slideshow_caption {
                    }
                }
            }
        }

        button {
            padding: 0.2em;
            height: auto;
            z-index: 9999;

            &:before {
                content: "\f104";
                font-family:"Font Awesome 6 Pro";
                font-weight: 300;
                height: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        .slick-next:before {
            content: "\f105";
            padding-left: 0.1em;
        }
    }

    .slick-prev, .slick-next {
        top: 48%;
        z-index: 9999;
    }

    .slick-prev {
        left: 1px;
    }

    .slick-next {
        right: 1px;
    }

    .slick-arrow:hover, .slick-arrow:focus, .slick-arrow:active {
        border: none;
    }

    .slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img {
        width: 100%;
        z-index: 0;
    }

    .slick-dots li button:before, .slick-dots li.slick-active button:before {color:$white;}

    

    /*Full width slider x3 with captions, dots and arrows */
   
    ul.responsive {
        /*padding-bottom: 3em;*/
        overflow: visible;

        .slick-list {

            .slick-track {

                .slick-slide {

                   img {@include transition(all 0.3s ease-in-out);}
                    
                    &:hover {
                        img {@include transform($zoom);@include transition(all 0.3s ease-in-out);}
                    }

                    .slideshow_caption {
                        /*text-align: center;*/
				        width: 80%;
                        background-color: rgba(0, 0, 0, 0.7);
                        opacity: 1;
                        padding: 0.5em;
                        bottom:0;
                        @include center(true, false);
                        @include transition(all 0.3s ease-in-out);
                    }

                }

            }
        }

      
        .slick-prev {left: 0 }
        .slick-next {right: 0}
        .slick-dots {bottom:0; li:before {display:none;}}
    }

    /*Full width slider with captions */
    ul.responsive3 {
        margin-bottom: 0;

        .slick-list {

            .slick-track {

                .slick-slide {
					height: 420px;
                    height: 95vh;
					
					&:before {content:""; position:absolute; display: block; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.45); }
                    picture, p, img {display:block; position:relative; width:100%; height:100%;}
                    img {
                        /*height: 420px;*/
                        max-width: none;
                        Width:100% !important; 
                        object-fit:cover;                      
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%,-50%);
                        transition: transform 6s;
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                        z-index: -1;

                    }
					
					p:last-of-type{margin-bottom: 0.75em;}
					
					a.button:first-of-type {animation-delay: 1s;}
					a.button {animation-delay: 1.5s;}
										
                }

                .slick-slide.slick-active  {

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(1.05);
                        -webkit-transform: translate(-50%, -50%) scale(1.05);
                        -ms-transform: translate(-50%, -50%) scale(1.05);
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                    }
					
					
										
                }


				.slideshow_caption {opacity:0; visibility:hidden;display:none; @include transition(all 0.5s ease-in-out); position: relative;}
				.slideshow_caption.activate {background: rgba(255, 255, 255,0); opacity:1; visibility: visible;display:block; text-align: center; @include transition(all 0.5s ease-in-out);}
                .slideshow_caption p.h2 {display:none;}
				
            }
        }
			
    }

// HOME TEMPLATE 2
body.Template-2 {
    ul.responsive3 > .slick-list > .slick-track > .slick-slide {
        height: 95vh;
        .slideshow_caption p.h2 {display:block;}
        .slideshow_caption p.h4 {display:none;}
    }
}

// HOME TEMPLATE 3
body.Template-3 {
    ul.responsive3 > .slick-list > .slick-track > .slick-slide {
        height: 100vh;
    }
}

// HOME TEMPLATE 4
body.Template-4 {
    ul.responsive3 > .slick-list > .slick-track > .slick-slide {
        height: 75vh;
    }
}

    /************************
RESPONSIVE VIDEO WRAPPER
************************/
    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 35px;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
/*********************
CUSTOMISE colorbox
*********************/
    #cboxContent {padding: 0 40px}
    
    #cboxCurrent, #cboxNext, #cboxNext, #cboxPrevious, #cboxClose {
        top: 4px;
    }

    #cboxNext, #cboxPrevious{
        top: 50%;
    }

    #cboxPrevious{
        left:0;
    }
    #cboxNext{
        right:0;
    }

    #cboxTitle {
        font-size: 120%;
        line-height: 1.8em;
    }

    #cboxLoadedContent {
        margin-top: 28px;
    }
    /*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        display: inline-block;
        height: 40px;
        width: 40px;
        text-align: center;
        position: fixed;
        bottom: 5em;
        right: 0.5em;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        white-space: nowrap;
        background:darken($color-tertiary, 5%);
        visibility: hidden;
        opacity: 0;
        @include transition(opacity .3s 0s, visibility 0s .3s);
        border-radius:50%;


        i {
            margin: auto;
            line-height: 2.5;
            @include center();
        }
    }

    .cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
        @include transition(opacity .3s 0s, visibility 0s .3s);
    }

    .cd-top.cd-is-visible {
        /* the button becomes visible */
        visibility: visible;
        opacity: 1;
    }

    .cd-top.cd-fade-out {
        /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
        opacity: .5;
    }

    .no-touch .cd-top:hover {
        background-color: #e86256;
        opacity: 1;
    }
/* used for pagination on the news listing page*/
div.new-items-pagination {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    background: lighten(#000000, 85%);
    margin: 1em auto;
    padding: 0;

    .news-item-label, .news-items-next {
        padding: 0.6em;
    }

    .news-items-next {
        text-align: right;
    }
}

// HOME TEMPLATE 2
body.Template-2 {
}

// HOME TEMPLATE 3
body.Template-3 {

}

// HOME TEMPLATE 4
body.Template-4 {
    .custom-shape-divider-bottom-1644471312 {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        z-index: 99;
    }

    .custom-shape-divider-bottom-1644471312 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 80px;
    }

    .custom-shape-divider-bottom-1644471312 .shape-fill {
        fill: $white;
    }

    #content .custom-shape-divider-bottom-1644471312 .shape-fill {
        fill: lighten($black, 5%);
    }
}