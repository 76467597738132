/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/
.mobileOnly {display:none!important;}
.desktopOnly {display:inline-block!important;}


/*sizing*/
h1, .h1 { font-size: 2.4em; }
h2, .h2 { font-size: 2em; }
.bigger, [data-control] {font-size:1.1em;}
h3, .h3 {font-size: 1.15em;}
#message {font-size:1em;}

/*************************
Colours
*************************/

.white { color: $white; }


/*********************
LAYOUT & GRID STYLES
*********************/
.row, body.home #content #leftcol > div > div, body.fullWidth #leftcol > div > div{
    padding: 2em 0;
}

/* Reduce padding on some rows*/
#breadcrumbs .row {padding: 1em 0;}

/*Increase padding content some rows */

/* Restrict width on some rows and content areas that behave like rows*/

		
/*********************
SPRITE
*********************/
		

    #logo {
        width:181px; height: 80px;
	    background-size: 181px 80px;
	}

/*********************
HEADER STYLES
*********************/

body.Home .header {
	/*height: 112.09px;*/
}

.header {

	    .row {
		    position: relative;
            min-height: 110px;
            flex-wrap: no-wrap;
	    }

	    #logo, #accountMenu {
            transform: none;
	    }

        #logo {
            left: 0%;
            top:0.5em;
        }

	    #accountMenu {
            right:0;
            max-width: none;
            @include center(false, true);

            a:first-of-type {
                display: block;
                margin-right: 0.5em;
            }
	    }

        #mainMenu {
            display: inline-block;
            position: absolute;
            text-align: left;
            width: auto;
            margin-top: 0.25em;
            @include center(false, true);
            left:185px;
        }
        .menu-btn {
            display: none;
            right: 0%;
            left: unset;
            bottom: 0%;
            top: unset;
        }
     		
}




/**************************
HERO - Home
***************************/

body:not(.home) #hero {

	.block {
       img {max-width:1200px;}
    }
}/*hero*/

body.Home #hero {
    .caption {
        width: 50%;
    }
}
/*********************
NAVIGATION STYLES
*********************/

/*#touchMenu*/
.menu-btn {display:none;}


.topMenu {
    margin-left: auto;
    margin-right: auto;
    clear: none;
    float: left;
    text-align: left;


    a.toggleMenu {
        display: none;
    }

    .nav {
        position: relative;
        width: auto;
        border: 0;
        display: inline;
        margin: 0 auto;



        > li {
            display: inline-block;
            padding: 0 0.5em;

            > a {
                outline: none;

                &:hover, &:focus {
                    background: transparent;
                }
            }
            /*Prominent contact link*/
            &:first-child {
                display: none;
            }

            > a, > a.parent {
                position: relative;
                background: transparent;
                padding: 0.25em 0em;

                &:hover, &:focus {
                    background: none;
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background-color: $color-primary;
                    visibility: hidden;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    @include transition(all 0.3s ease-in-out 0s);
                }

                &:hover:after, &.activerootmenulink:after {
                    visibility: visible;
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
        }

        li {
            position: relative;

            a {
                padding: 0.5em 0em;
                background: none;
                border-bottom: none;


                &.parent:before {
                    display: none;
                }
            }
            /* highlight current page */
            a.activerootmenulink {
                color: $color-primary;
            }

            &:first-child {
            }

            &:last-child {
            }
            /*
			plan your menus and drop-downs wisely.
			*/
            ul,
            ul.sub-menu,
            ul.children {
                position: absolute;
                z-index: 9999;
                left: -9999px;
                border-top: 2px solid $color-primary;

                li {

                    a {
                        padding: 0.5em 0.5em;
                        display: block;
                        width: 250px;
                        border-top: none;
                        border-radius: 0;
                        margin-right: 0;


                        &:hover,
                        &:focus {
                            border-top: none;
                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {

                        a {
                        }
                    }
                    /*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
                    ul {
                        border-top: none;
                    }
                }
            }
            /* showing sub-menus */
            &:hover ul {
            }
        }
        /* end .menu ul li */
    }
    /* end .nav */
} /* end .topMenu */


    .darkHeader .topMenu .nav > li > a:after {background-color: $color-primary; @include transition(all 0.3s ease-in-out 0s);}

    .nav > li.hover > ul {
        left: 0;
    }

    .nav li li ul {
        left: -9999px;
        z-index: 99;
        position: absolute;
    }

    .nav li li.hover ul {
        left: 100%;
        top: 0;
        z-index: 99;
        position: absolute;
    }





/*********************
CONTENT STYLES
*********************/

.col-2 {
    > div, > li {
        grid-column: span 1;
    }
}

/*********************
BLOCK LAYOUT
**********************/	


.columnlayout {	
	> div, > li, > article {width: calc(33% - 1em);}
    article.newsBlock {width: 25%; }
}
#gallery li {width: 30%; padding: 1em;}

.columnlayout.grid-1, .columnlayout.grid-2 {
    > div, > li {
        width: 18%;
    }
}

/*********************
FOOTER STYLES
*********************/

     body.Template-2 {
        #bottomBlocks {
            .respond-form {padding-top:1em;}
        }
        #footer {
            .last {text-align:right;}
        }
    }

     body.Template-3 {
        #footer {
            .last {text-align:right;}
        }
    }

    /*Bottom Blocks*/

    #bottomBlocks .block {
        margin-bottom: 2em;
        margin: 0 auto;
    }

    /*layout needed for list items */
    #bottomBlocks #block_26 {

        ul {
           
            li {
                display:inline-block;
                width: 48%;
                margin-bottom: 0.5em;

            }
        }

    }

/* ================================================ */
/* SLIDESHOWS */
/* ================================================ */  

/*Full width slider */
body.Home #hero {
	overflow:hidden;
	.slick-slider { }
}

ul.responsive3 {
	max-height: none;
	.slick-list {
			
		.slick-track {
				
			.slick-slide {
				/*height:500px;*/
				
				&:before {background-color: rgba(0, 0, 0, 0.45); }
				
				img {
                        /*height: 420px;*/
                    }	
				.slideshow_caption { text-align:left; /*margin: 0 auto;padding:2em; max-width:50%;*/ }
			}
				
		}
			
	}

    .slick-slider .slick-active img{ margin-left: 0;}

    .slick-prev, .slick-next { top: 50%; }
    .slick-next { right: 0px; }
    .slick-prev { left: 0; }
    .slick-slider .slick-track, .slick-slider .slick-list { -webkit-perspective: 1000px; }
}

// HOME TEMPLATE 2
body.Template-2, body.Template-3, body.Template-4 {
   .slick-list {
			
		.slick-track {
				
			.slick-slide {
	
				.slideshow_caption { text-align:center; }
			}
				
		}
    }
}



/*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        height: 50px;
        width: 50px;
    }

/*********************
Sticky CTA
*********************/

#stickyCTA {
	 
}