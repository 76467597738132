/******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/
h1, .h1  { line-height: 1.2;}

/*margins*/
h2, .h2, .gmb-review p{ margin: 0.5em 0;}
#bottomBlocks h4, #bottomBlocks .h4 { margin:  2em 0 1em 0;}

/*sizing*/
h1, .h1 { font-size: 3.2em; }
body.landing .h1 { font-size: 3em; }
h2, .h2 { font-size: 2.4em; }
#accountMenu > a:first-of-type {font-size:1.2em;}


.topMenu .nav > li > a {
    @include bp(papa-screen) {
        font-size: 120%;

    }
}

.topMenu {
    .nav {
        > li {
            @include bp(papa-screen) {
                padding: 0 0.75em;
            }
        }
    }

}

    /*********************
LAYOUT & GRID STYLES
*********************/

    .row, body.home #content #leftcol > div > div, body.fullWidth #leftcol > div > div {
        padding: 3em 0;
    }

    .header {

        .clearHeader {

            > .row {
                min-height: 120px;
                @include transition(all 0.3s ease-in-out);
            }

            #logo {
                width: 225px;
                height: 100px;
                background-size: 225px 100px;
                @include transition(all 0.3s ease-in-out);
            }
        }

        .darkHeader {

            > .row {
                min-height: 110px;
                @include transition(all 0.3s ease-in-out);
            }

            #logo {
                width: 203px;
                height: 90px;
                background-size: 203px 90px;
                @include transition(all 0.3s ease-in-out);
            }
        }
    }
    /*********************
HEADER STYLES
*********************/
    // HOME TEMPLATE 2

    .header {
        #mainMenu {
            left: 230px;
        }

        #accountMenu {

            a:first-of-type {
                display: inline-block;
            }
        }
    }
    /**************************
HERO - Home
***************************/
    /*body.Home #hero {max-height:75vh;}*/
    body:not(.Home) #hero {
        /*height:300px;*/
        overflow: hidden;

        .block > p img {
            min-width: 100%;
            max-width: initial;
            height: auto;
        }
    }

    body.Home #hero .row .slideshow_caption {
        width: 40%;
    }


    #hero .row {
        width: 60%;
    }
    /*********************
NAVIGATION STYLES
*********************/
    /*********************
CONTENT STYLES
*********************/

    #content #leftcol > div > .first {
        padding: 0 2em;
    }
    /*********************
BLOCK LAYOUT
**********************/


    .columnlayout {

        > div, li {
        }
        /*Top row needs border adding seperatley to prevent double borders*/
        > div:nth-child(4), li:nth-child(4) {
        }
    }


    #bottomBlocks {
        .block {
            margin-bottom: 0em;
        }
    }
    /* LEFT BLOCKS - Mission statement*/
    #homeMission {
        .row {
            height: 60vh;
            display: flex;
            align-items: center;
        }
    }
    /*Full width slider */
    ul.responsive3 {

        .slick-list {
            .slick-track {
                .slick-slide {

                    img {
                    }
                }
            }
        }
    }

